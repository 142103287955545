export const list = {
  properties: [
    {
      id: 0,
      index: 0,
      title: 'FACE DETECTION',
      descriptionLeft: [
        ' A full stack web application built using ReactJs and CSS on the frontend.',
        'PostgreSQL used for the database.',
        'The backend handled by Express and Node.js.',
        'The additional components used were ClarifaiAPI and react-tilt. ',
        'The site is live. The site is hosted on heroku and can be visited using the link.',
      ],
      image: require('./images/face_detect-clear.png'),
      imageDescription: 'React, Express, Node.js, PostgreSQL',
      backDescription:
        'This is a website build using React, Node.js, PostgreSQL and ClarifaiAPI. Visit the links for more info.',
      liveUrl: 'https://face-detect-fend.herokuapp.com/',
      githubUrl: 'https://github.com/anishchand99/facedetect_frontend',
      videoUrl: require('./videos/faceDetect.mp4'),
    },
    {
      id: 1,
      index: 1,
      title: 'TRAFFIC BOT',
      descriptionLeft: [
        'A full stack web application built to collect parameters that can be used to gauge the possibility of browser fingerprinting methods.',
        'Built as part of undergraduate research.',
        'The frontend built using React.',
        'The backend handeled by Express and Node.js. ',
        'The database used to store parameters was PostgreSQL.',
        'The site is live. The can be visited using the link.',
      ],
      image: require('./images/bot.png'),
      imageDescription: 'REACT, EXPRESS, PostgreSQL',
      backDescription:
        'This is a website build using React, Node.js, PostgreSQL and ClarifaiAPI. Visit the links for more info.',
      liveUrl: 'http://twoface.quickwebchecker.com',
      githubUrl: 'https://github.com/anishchand99/bot',
      videoUrl: '',
    },
    {
      id: 2,
      index: 2,
      title: 'JOB TRACKER',
      descriptionLeft: [
        'A Web Application used to keep track of the online job applicaitons.',
        'Features to add separate lists to group different job applications into different categories.',
        'Implementation of react-chart-js-2  for data visualisation of the application progress',
        'Built using React, Node.js, Express, and GraphQL',
        'Can be visited using the link.',
      ],
      image: require('./images/job.jpg'),
      imageDescription: 'REACT, EXPRESS, PostgreSQL',
      backDescription:
        'Built using React, Node.js, Express and PostgreSQL. Visit the links for more info.',
      liveUrl: 'https://job-track-app2020.herokuapp.com/',
      githubUrl: 'https://github.com/anishchand99/job-track-front',
      videoUrl: require('./videos/job-track.mp4'),
    },
    {
      id: 3,
      index: 3,
      title: 'PATHFINDER',
      descriptionLeft: [
        'A Web Application built to visualize pathfinging algorithms.',
        'Implementation of Pathfinding algorithms like Dijkstra, A* search, BFS, and DFS.',
        'Ability to add walls and adjust speed.',	
        'Built using React and CSS animations.',
        'Can be visited using the link.',
      ],
      image: require('./images/path.png'),
      imageDescription: 'REACT, CSS Animations',
      backDescription:
        'Built using React and CSS animations. Visit the links for more info.',
      liveUrl: 'https://anishchand99.github.io/pathfinder/',
      githubUrl: 'https://github.com/anishchand99/pathfinder',
      videoUrl: require('./videos/pathfinder.mp4'),
    },
    {
      id: 4,
      index: 4,
      title: 'TRIVIA APP',
      descriptionLeft: [
        'A Web App built as a fun trivia Q/A application.',
        'Use of OpenTrivia API for the trivia questions for multiple categories and multiple levels of difficulty.',
        'Built using HTML, CSS and VanillaJS',
        'Can be visited using the link.',
      ],
      image: require('./images/quiz.png'),
      imageDescription: 'HTML, CSS, VanillaJS',
      backDescription:
        'Built using VanillaJS and OpenTrivia API. Visit the links for more info.',
      liveUrl: 'https://anishchand99.github.io/TriviaApp/index.html',
      githubUrl: 'https://github.com/anishchand99/TriviaApp',
      videoUrl: require('./videos/trivia.mp4'),
    },
    {
      id: 5,
      index: 5,
      title: 'CAMERA2BASIC',
      descriptionLeft: [
        'An Android application built using the Camera2Basic api.',
        'Built as part of Mobile Application Development class.',
        'Built as a group project featuring four students.',
        'Written in Java and used SQL for database.',
        'Built to add manual camera contorl features that are pay-only in the play store.',
        'Can be visited using the link.',
      ],
      image: require('./images/camera.png'),
      imageDescription: 'Android, Java, Camera2Basic, SQLite',
      backDescription:
        'This is an app built with primary focus on manual camera controls. It was built using Java for Android and Camera2Basic API.Visit the links for more info.',
      liveUrl: '',
      githubUrl: 'https://github.com/anishchand99/',
      videoUrl: '',
    },
    {
      id: 6,
      index: 6,
      title: 'CHESS AI',
      descriptionLeft: [
        'A Chess AI that is buit using minimax algorithm.',
        'Optimized through the use of alpha beta pruning.',
        'Written in Java',
        'Can be visited using the link.',
      ],
      image: require('./images/chess.png'),
      imageDescription: 'AI, Java',
      backDescription:
        'This is an app built with primary focus on manual camera controls. It was built using Java for Android and Camera2Basic API.Visit the links for more info.',
      liveUrl: 'https://github.com/anishchand99/chessbot',
      githubUrl: 'https://github.com/anishchand99/chessbot',
      videoUrl: require('./videos/chessAI.mp4'),
    },
    {
      id: 7,
      index: 7,
      title: 'BACK GEN',
      descriptionLeft: [
        'A Web Application used to dynamically generate background combination.',
        'Implementation of plain background, linear gradient and radial gradient.',
        'Built using HTML, CSS and VanillaJS',
        'Can be visited using the link.',
      ],
      image: require('./images/back-color.png'),
      imageDescription: 'REACT, EXPRESS, MYSQL',
      backDescription:
        'Built using HTML, CSS and VanillaJS as a fun tool. Visit the links for more info.',
      liveUrl: 'https://anishchand99.github.io/background-generator/index.html',
      githubUrl: 'https://github.com/anishchand99/background-generator',
      videoUrl: require('./videos/backgen.mp4'),
    },
  ],
};
