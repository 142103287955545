import React from 'react';
import github from './images/github-logo.png';
import linkedin from './images/linkedin.png';
export const resumeConstant = [
  <div className='resume'>
    <div className='resume-links end-to-end'>
      {' '}
      <div className='sub-head left-end'>Anish Chand</div>
      <div className='location right-end'>
        {' '}
        <span className='logoImage'>
          <a
            href='https://github.com/anishchand99'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img alt='logo' className='logoImage' src={github} />
          </a>
        </span>
        <span className='logoImage'>
          <a
            href='https://www.linkedin.com/in/anish-chand/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img alt='logo' className='logoImage' src={linkedin} />
          </a>
        </span>
      </div>
    </div>
    <div className='highlight'>ABOUT</div>
    <div className='description'>
      {' '}
      Software Engineer with demonstrated ability in problem solving and
      object-oriented programming.
    </div>
    {/* education */}
    <div className='highlight'>EDUCATION</div>
    <div className='end-to-end'>
      {' '}
      <div className='sub-head left-end'>University of New Orleans</div>
      <div className='location right-end'> New Orleans, LA</div>
    </div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>
        Bachelor of Science, Computer Science
      </div>
      <div className='date'> Aug 2016 - May 2020</div>
    </div>
    <div className='sub-head right-end'> GPA: 3.95/4.0</div>
    {/* experience */}
    <div className='highlight'>EXPERIENCE</div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>Software Engineer</div>
      <div className='date right-end'> Aug 2020-Present</div>
    </div>
    <div className='end-to-end'>
      <div className='left-end'>Punctual Abstract Co. Inc</div>
      <div className='location right-end'>Harvey, LA</div>
    </div>
    <div className='description'>
      {' '}
      <ul>
        <li>
          Worked on the company's flagship instant product to bring down the
          time for an order completion from over 30 minutes to under 5 minutes
          in most cases.
        </li>
        <li>
          Built functionality for generating reports in word document and pdf
          formats necessary to complete orders received from the clients
          reducing the manual process by over 95%.
        </li>
        <li>
          Upgraded the email connectivity in the company's dedicated software
          from deprecated imap protocol to Microsoft's latest Graph API.
        </li>
        <li>
          Built different APIs using company's framework built in PHP to add
          functionality and support existing legacy software and implement
          company's next generation software.
        </li>
        <li>Optimized SQL queries to improve the speed in some instances.</li>
      </ul>
    </div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>Undergraduate Research Assistant</div>
      <div className='date right-end'> Jan 2019-Apr 2020</div>
    </div>
    <div className='end-to-end'>
      <div className='left-end'>University of New Orleans</div>
      <div className='location right-end'>New Orleans, LA</div>
    </div>
    <div className='description'>
      {' '}
      <ul>
        <li>
          {' '}
          Worked on an earlier prototype of an anti-phishing evaluation system
          that later got published at the 30th USENIX Security Symposium
          <u> (https://www.usenix.org/system/files/sec21-acharya.pdf) </u>.
        </li>
        <li>
          Built benign clones of financial websites to fingerprint the web
          scanning engines.
        </li>
        <li>
          Implemented Google reCaptcha v3 to analyze the scores of web scanning
          engines to gauge the possibility of fingerprinting.
        </li>
        <li>
          Analyzed security bot traffic based on the data obtained from a
          website that captured data from the visitor’s HTTP headers, IP, mouse
          and keyboard for fingerprinting.
        </li>
        <li>
          Website frontend built in ReactJs and backend web server implemented
          in Node.js with PostgreSQL as the database.
        </li>
        <li>
          Presented the research findings at the InnovateUNO 2019, the
          university's annual research symposium
        </li>
      </ul>
    </div>
  </div>,

  // right side

  <div className='resume rightSide'>
    {/* experience */}
    <div className='end-to-end'>
      <div className='sub-head left-end'>Programming Mentor</div>
      <div className='date right-end'> Mar 2018-Feb 2020</div>
    </div>
    <div className='end-to-end'>
      <div className='left-end'>University of New Orleans</div>
      <div className='location right-end'>New Orleans, LA</div>
    </div>
    <div className='description'>
      {' '}
      <ul>
        <li>
          {' '}
          Mentored students one-to-one on object-oriented concepts in Java as
          part of the university's Introduction to Programming course
          (CSCI-1583).{' '}
        </li>
        <li>
          Helped freshmen understand core java concepts and develop their
          problem-solving ability as a Java lab mentor for university's
          CSCI-1581 lab.
        </li>
      </ul>
    </div>
    {/* programming mentor */}
    <div className='end-to-end'>
      <div className='sub-head left-end'>Peer Mentor</div>
      <div className='date right-end'> Aug 2018-Dec 2018</div>
    </div>
    <div className='end-to-end'>
      <div className='left-end'>University of New Orleans</div>
      <div className='location right-end'>New Orleans, LA</div>
    </div>
    <div className='description'>
      {' '}
      <ul>
        <li>
          {' '}
          Was a mentor for the ‘University Success’ class for university
          freshmen that helped freshmen smoothly transition to the college life.{' '}
        </li>
        <li>
          Presented class and led discussions on topics of ‘Time Management’ and
          ‘NoteTaking and Reading’.
        </li>
      </ul>
    </div>
    {/* projects */}
    <div className='highlight'>PROJECTS</div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>MedLife Stories</div>
      <div className='date right-end'>
        {' '}
        <a
          href={'https://medlifestories.com/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          Live Site
        </a>
      </div>
    </div>
    <div className='description'>
      <ul>
        <li>
          A full stack web app with secure admin login portal that uses user
          access levels authorization to manage blogs, events, projects and
          mailing list.
        </li>
        <li>
          Email integration using nodemailer for two-factor authentication and
          sending mails and newsletter to the subscribers.
        </li>{' '}
        <li>
          Implemented a rich text editor in React to provide support for font,
          color, images and emoji customizations while writing and editing
          blogs.
        </li>
        <li>
          Technologies used include React, Express, Node.js, PostgreSQL, Docker.
        </li>
      </ul>
    </div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>Facial Recognition App</div>
      <div className='date right-end'>
        {' '}
        <a
          href={'https://face-detect-fend.herokuapp.com/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          Live Site
        </a>
      </div>
    </div>
    <div className='description'>
      <ul>
        <li>A full stack web app that detects faces in the uploaded images.</li>
        <li>
          Built using React, Express, Node.js, PostgreSQL and Clarifai API.
        </li>
      </ul>
    </div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>JobTrack App</div>
      <div className='date right-end'>
        {' '}
        <a
          href={'https://job-track-app2020.herokuapp.com/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          Live Site
        </a>
      </div>
    </div>
    <div className='description'>
      <ul>
        <li>
          Developed a full stack webapp with ability to create separate lists to
          keep track of job applications.
        </li>
        <li>
          Ability to visualize the statistics of job applications including
          approved, pending, removed and total daily applications.
        </li>
        <li>Built using React, Node.js, Express and PostgreSQL.</li>
      </ul>
    </div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>PathFinding Visualizer</div>
      <div className='date right-end'>
        {' '}
        <a
          href={'https://anishchand99.github.io/pathfinder/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          Live Site
        </a>
      </div>
    </div>
    <div className='description'>
      <ul>
        <li>
          A web app that visualizes pathfinding algorithms like A* Search,
          Dijkstra, BFS and DFS.
        </li>
        <li>Built with React using CSS animations.</li>
      </ul>
    </div>
    <div className='end-to-end'>
      <div className='sub-head left-end'>AI Chess Bot</div>
      <div className='date right-end'>
        {' '}
        <a
          href={'https://github.com/anishchand99/chessbot'}
          target='_blank'
          rel='noopener noreferrer'
        >
          Live Site
        </a>
      </div>
    </div>
    <div className='description'>
      <ul>
        <li>
          A Java chess bot implementing the minimax algorithm with alpha beta
          pruning.
        </li>
      </ul>
    </div>
    <div className='highlight'>SKILLS</div>
    <div className='sub-head'>
      PHP, Java, HTML, CSS, JavaScript, Python, React, Node.js, Docker
    </div>
  </div>,
];
